import React, { useEffect, useLayoutEffect, useState } from "react";
import {Link, useStaticQuery, graphql} from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import NestedContentRow from "../components/nestedContentRow";
import ALTile from "../components/ALTile";
import ALSubscribe from "../components/ALSubscribe";
import smoothScrollTo from "../components/smoothScroll";

const BlogPressPage = ({location}) => {
    const blogElem = location.state;
    const imgData = useStaticQuery(graphql`
        query {
            blogPressHeader: file(relativePath: { eq: "BlogPressHeader.png" }) {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            blogimg1: file(relativePath: { eq: "blogImg1.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 350 maxHeight: 200 ) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            blogimg2: file(relativePath: { eq: "blogImg2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 350 maxHeight: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            blogimg3: file(relativePath: { eq: "blogImg3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 350 maxHeight: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pcimg1: file(relativePath: { eq: "pcImg1.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 350 maxHeight: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pcimg2: file(relativePath: { eq: "pcImg2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 180) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pcimg3: file(relativePath: { eq: "pcImg3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 350 maxHeight: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const [headerImgVisible, isHeaderVisible] = useState(false);
    const scrollTo = pageID => {
        const scrollTop = document.getElementById(pageID);
        if(scrollTop && scrollTop.offsetTop) {
            smoothScrollTo(scrollTop.offsetTop, 100);
        }
    };

    const toggleHeaderImage = _ => {
        if(window.innerWidth <= 1366) {
            return isHeaderVisible(false);
        }
        isHeaderVisible(true);
    };

    useEffect( _ => {
        if(blogElem && blogElem.component) {
            scrollTo(blogElem.component);
        }
        toggleHeaderImage();
        window.addEventListener("resize", toggleHeaderImage);
        return function () {
            window.removeEventListener("resize", toggleHeaderImage);
        }
    });

    return (
        <Layout pageID={"blogpress"}>
            <SEO
                title="Blog & Press"
                description={"Check out the latest product news and releases, ALMobile in the media, and our blog posts about construction industry-related topics to stay informed."}
            />
            <div className="contentRow pageHeader">
                <div className="subContentRow">
                    <NestedContentRow
                        iconData={
                            (headerImgVisible) ?
                                {
                                    iconType: "fluid",
                                    icon: imgData.blogPressHeader.childImageSharp.fluid,
                                    iconAlt: "Blog Press Header"
                                }
                                :
                                {}
                        }
                        textData={{
                            level1: "LATEST BLOG POST",
                            level2: "How ALMobile Makes Joint Ventures Simple",
                            level3: <div className={"level3"}>
                                        <a
                                            className={"link btn"}
                                            href={"/downloads/Blog Post_HOW TO MAKE YOUR JOINT VENTURE PROJECTS SIMPLE_AD.pdf"}
                                            target="_blank"
                                            rel={"noreferrer noopener"}
                                        >
                                            Read Story
                                        </a>
                                    </div>
                        }}
                    />
                </div>
            </div>
            <ALSubscribe/>
            <div id={"blog"} className={"contentRow"}>
                <div className={"subContentRow"}>
                    <div className={"blogHeader"}>ALMobile Blog</div>
                    <div className={"blogTiles"}>
                        <ALTile
                            className={"blog3"}
                            iconType={"fluid"}
                            icon={imgData.blogimg3.childImageSharp.fluid}
                            iconAlt={"Blog Img 3"}
                            mainText={"How To Make Your Joint Venture Projects Simple"}
                            customBtn={
                                <div className={"level3"}>
                                    <a
                                        className={"link btn"}
                                        href={"/downloads/Blog Post_HOW TO MAKE YOUR JOINT VENTURE PROJECTS SIMPLE_AD.pdf"}
                                        target="_blank"
                                        rel={"noreferrer noopener"}
                                    >
                                        Read Story
                                    </a>
                                </div>
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"blog1"}
                            iconType={"fluid"}
                            icon={imgData.blogimg1.childImageSharp.fluid}
                            iconAlt={"Blog Img 1"}
                            mainText={"Five Things You Should be Asking Your Software Vendor"}
                            customBtn={
                                <div className={"level3"}>
                                    <a
                                        className={"link btn"}
                                        href={"/downloads/Blog Post_Five Things You Should be Asking Your Software Vendor_AD.pdf"}
                                        target="_blank"
                                        rel={"noreferrer noopener"}
                                    >
                                        Read Story
                                    </a>
                                </div>
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"blog2"}
                            iconType={"fluid"}
                            icon={imgData.blogimg2.childImageSharp.fluid}
                            iconAlt={"Blog Img 2"}
                            mainText={"Why You Should be Tracking Jobsite Productivity"}
                            customBtn={
                                <div className={"level3"}>
                                    <a
                                        className={"link btn"}
                                        href={"/downloads/Blog Post_Why You Should be Tracking Jobsite Productivity_AD.pdf"}
                                        target="_blank"
                                        rel={"noreferrer noopener"}
                                    >
                                        Read Story
                                    </a>
                                </div>
                            }
                            useWebinar={false}
                        />
                    </div>
                </div>
            </div>
            <div id={"pressCoverage"} className={"contentRow"}>
                <div className={"subContentRow"}>
                    <div className={"pressCoverageHeader"}>Press Coverage</div>
                    <div className={"pressCoverageTiles"}>
                        <ALTile
                            className={"pc1"}
                            mainText={"Construction Executive"}
                            subText={
                                <a
                                    className={"link"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    href={"http://constructionexec.com/article/technology-and-software-rundown-45"}
                                >
                                    Technology and Software Rundown
                                </a>
                            }
                            customBtn={
                                <div className={"pressCoverageDate"}>
                                    Tuesday, March 21, 2017
                                </div>
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"pc2"}
                            mainText={"CONEXPO-CON/AGG"}
                            subText={
                                <a
                                    className={"link"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    href={"https://www.conexpoconagg.com/news/how-to-deploy-software-and-have-your-team-actually/"}
                                >
                                    How To Deploy Software - And Have Your Team Actually Use It
                                </a>
                            }
                            customBtn={
                                <div className={"pressCoverageDate"}>
                                    Wednesday, April 4th, 2018
                                </div>
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"pc3"}
                            mainText={"Construction Business Owner"}
                            subText={
                                <a
                                    className={"link"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    href={"https://www.constructionbusinessowner.com/strategy/how-can-company-improve-its-percentage-completion-rate"}
                                >
                                    How Can a Company Improve Its Percentage of Completion Rate?
                                </a>
                            }
                            customBtn={
                                <div className={"pressCoverageDate"}>
                                    Tuesday, May 1st, 2018
                                </div>
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"pc4"}
                            mainText={"The ConTechCrew Podcast"}
                            subText={
                                <a
                                    className={"link"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    href={"https://vimeo.com/376826924"}
                                >
                                    The ConTechCrew 128: Revisiting That Ancient Relic The Palm Pilot
                                </a>
                            }
                            customBtn={
                                <div className={"pressCoverageDate"}>
                                    Monday, July 25, 2018
                                </div>
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"pc5"}
                            mainText={"The ConTechCrew Podcast"}
                            subText={
                                <a
                                    className={"link"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    href={"https://twitter.com/TheConTechCrew/status/1011679691869020160"}
                                >
                                    #CFMAconf Exhibition Hall Chat 6: @AustinLaneTech #CTCNews
                                </a>
                            }
                            customBtn={
                                <div className={"pressCoverageDate"}>
                                    Tuesday, June 26, 2018
                                </div>
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"pc6"}
                            mainText={"Constructech"}
                            subText={
                                <a
                                    className={"link"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    href={"https://constructech.com/tp-19-austin-lane-technologies/"}
                                >
                                    Constructech Top Products 2019
                                </a>
                            }
                            customBtn={
                                <div className={"pressCoverageDate"}>
                                    Wednesday, January 1st, 2019
                                </div>
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"pc7"}
                            mainText={"CIO Bulletin"}
                            subText={
                                <a
                                    className={"link"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    href={"http://www.ciobulletin.com/magazine/austin-lane-technologies-inc-productivity-tools-for-your-workforce"}
                                >
                                    30 Hottest Companies to Watch 2019
                                </a>
                            }
                            customBtn={
                                <div className={"pressCoverageDate"}>
                                    Thursday, August 8th, 2019
                                </div>
                            }
                            useWebinar={false}
                        />
                        <ALTile
                            className={"pc8"}
                            mainText={"Construction Executive"}
                            subText={
                                <a
                                    className={"link"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                    href={"https://www.constructionexec.com/article/construction-technology-and-software-rundown-october-4-2019"}
                                >
                                    Construction Technology and Software Rundown: October 4, 2019
                                </a>
                            }
                            customBtn={
                                <div className={"pressCoverageDate"}>
                                    Monday, September 30, 2019
                                </div>
                            }
                            useWebinar={false}
                        />
                    </div>
                </div>
            </div>
            <div id={"pressRelease"} className={"contentRow"}>
                <div className={"subContentRow"}>
                    <div className={"pressReleaseHeader"}>Press Releases</div>
                    <div className={"pressReleaseTiles"}>
                        <a
                            className={"pr1"}
                            href={"/downloads/PRALCLoud.pdf"}
                            target="_blank"
                            rel={"noreferrer noopener"}
                        >
                            <ALTile
                                className={"pr1"}
                                iconType={"fluid"}
                                icon={imgData.pcimg1.childImageSharp.fluid}
                                iconAlt={"Future of Jobsite Intelligence"}
                                mainText={"ALMobile Cloud"}
                                subText={"March 8, 2019"}
                                useWebinar={false}
                            />
                        </a>
                        <a
                            className={"pr2"}
                            href={"/downloads/PRAL20.pdf"}
                            target="_blank"
                            rel={"noreferrer noopener"}
                        >
                            <ALTile
                                className={"pr2"}
                                iconContainer={true}
                                iconType={"fluid"}
                                icon={imgData.pcimg2.childImageSharp.fluid}
                                iconAlt={"20th Anniversary of Austin Lane"}
                                mainText={"Austin Lane 20th Anniversary"}
                                subText={"Feb 3, 2019"}
                                useWebinar={false}
                            />
                        </a>
                        <a
                            className={"pr3"}
                            href={"/downloads/PRGTE.pdf"}
                            target="_blank"
                            rel={"noreferrer noopener"}
                        >
                            <ALTile
                                className={"pr3"}
                                iconType={"fluid"}
                                icon={imgData.pcimg3.childImageSharp.fluid}
                                iconAlt={"Grid Time Entry"}
                                mainText={"Grid Time Entry"}
                                subText={"Oct 22, 2017"}
                                useWebinar={false}
                        />
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default BlogPressPage
